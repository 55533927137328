import request from '@/utils/request';

// 根据字典类型查询字典数据信息
export function getDicts(dictType) {
  return request({
    url: '/system/dict/data/type/' + dictType,
    method: 'get'
  })
}

//图片验证码
export function graphicVerifCode(data) {
  return request({
    url: '/api/sale/sms/graphicVerifCode',
    method: 'post',
    data
  })
}

// 上传文件 
export function uploadFile(data) {
  return request({
    url: '/api/sale/fileManage/uploadFile',
    method: 'post',
    Headers: {'X-Requested-With': 'multipart/form-data'},
    data
  })
}

//查询产品详情
export function annAndProt(data) {
  return request({
    url: '/api/sale/prod/fundBase/annAndProt',
    method: 'post',
    data
  })
}