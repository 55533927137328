import request from '@/utils/request'

// 我的资产-快速提现
export function redeemQuick(data) {

  return request({
    url: '/api/sale/trade/xjb/redeemQuick',
    method: 'post',
    data
  })
}


// 我的资产-快速提现最大份额
export function subtractFastRedeem(data) {
  return request({
    url: '/api/sale/trade/xjb/subtractFastRedeem',
    method: 'post',
    data
  })
}

// 查询用户名下超包账户列表银行卡
export function findXjbAccoList(data) {
  return request({
    url: '/api/sale/trade/xjb/findXjbAccoList',
    method: 'POST',
	data
  })
}
// 查询用户名下超包账户列表对应账户每天可用钱
export function findXjbBalance(data) {
  return request({
    url: '/api/sale/trade/xjb/findXjbBalance',
    method: 'POST',
	data
  })
}
// 惠金宝-银行卡信息
export function bankInfo(data) {

  return request({
    url: '/api/sale/trade/xjb/bankInfo',
    method: 'post',
    data
  })
}

// 我的资产-普通提现
export function redeem(data) {

    return request({
      url: '/api/sale/trade/xjb/redeem ',
      method: 'post',
      data
    })
  }