// author:马云阳；description:众惠-交易流程-定投
<template>
  <section class="PublicFundProcess ">
    <site-nav>
      <span>&gt;</span>
      <RouterLink :to="{name:'FundMarket'}">基金超市</RouterLink>
      <span>&gt;</span>
      <span>{{funddata.fundName}}</span>
    </site-nav>
    <!-- <NavRightSide/> -->
    <div class="publicfundprocess_cont fixed_width">
      <a @click="togos" class="title_">
        <span class="iconfont">&#xe665;</span>{{funddata.fundName}}
      </a>
      <div class="side_cont">
        <ProgressBar :sideData="side" />
      </div>
      <div class="solids_"></div>
      <div class="card_">
        <div class="titles_">定投基金</div>
        <div class="card_new">
          <div class="card_l">
            <div class="right_ti">
              <div class="nema_ti">
                <span class="num">{{funddata.fundCode}}</span>
                <span class="name_">{{funddata.fundName}}</span>
              </div>
              <div class="tags">
                <div class="tag_">
                    {{sys_fund_riskleveldata[funddata.ofundRisklevel]||'--'}}
                 </div>
                <div class="tag_">
                    {{sys_fund_typedata[funddata.ofundType]||'--'
                    }}
                </div>
                <div class="tag_">{{sys_fund_share_typedata[funddata.shareType]||'--'}}</div>
               </div>
            </div>
            <a @click="topage" class="right_btn"><span>更换产品</span><span style="color:#CE9B63;margin-right:6px;" class="iconfont">&#xe69b;</span></a>
          </div>
        </div>
      </div>
      <div class="play_type">
        <div class="ti_">支付方式</div>
        <div class="bank_card">
          <div @click="bankswith(2)" :class="bankindex==2?'bank_listac':''" class="bank_list">
            <img v-if="bankindex!=2" src="@/static/img/trade_flow/trade-bankpay-unselect.png" alt="" class="l_imgs">
            <img v-if="bankindex==2" src="@/static/img/trade_flow/trade-bankpay-select.png" alt="" class="l_imgs">
            <div :class="bankindex==2?'c_nameac':''" class="c_name">快捷支付</div>
            <div class="r_share">单日限额 <span class="color_">{{banklist.singeldayLimit||0}}元</span>，单笔限额 <span
                class="color_">{{banklist.singelLimit||0}}元</span></div>
            <div v-if="bankindex==2" class="right_true"><span>✓</span></div>
          </div>
          <div class="bankcard_" v-if="bankindex==2">
            <div :class="cardindex==i?'backs_ac':''" @click="getcard(item,i)" v-for="(item,i) in play" :key="i"
              class="banks_">
              <div class="top_ban">
                <img :src="item.logoUrl" alt="">
                <span class="bank_name">{{item.bankName||''}}</span>
              </div>
              <div class="cardnum_">{{item.bankAccount||''}}</div>
              <div v-if="cardindex==i" class="right_true"><span>✓</span></div>
            </div>
            <a @click="topagebank" class="addbank_">
              <div style="margin-top:4px;transform: rotate(45deg);margin-right:6px"><span
                  style="font-size:18px;color:#bdc0cb;" class="iconfont">&#xe6ab;</span></div>
              <span class="add_">添加银行</span>
            </a>
          </div>
          <div  @click="bankswith(1)" :class="bankindex==1?'bank_listac':''" class="bank_list">
            <img v-if="bankindex!=1" src="@/static/img/trade_flow/trade-huijinbao-unselect.png" alt="" class="l_imgs">
            <img v-if="bankindex==1" src="@/static/img/trade_flow/trade-huijinbao-select.png" alt="" class="l_imgs">
            <div :class="bankindex==1?'c_nameac':''" class="c_name">惠金宝</div>
            <div class="r_share">当前可用余额 <span class="color_">{{superAccts.enableShare||"0"}}元</span></div>
            <div v-if="bankindex==1" class="right_true"><span>✓</span></div>
          </div>
          <div v-if="bankindex==1" class="bankcard_">
            <div :class="xjbindex==k?'backs_ac':''" @click="getcardxjb(items,k)" v-for="(items,k) in xjbList" :key="k"
              class="banks_">
              <div class="top_ban">
                <img :src="items.logoUrl" alt="">
                <span class="bank_name">{{items.bankName||''}}</span>
              </div>
              <div class="cardnum_">{{items.bankAccount||''}}</div>
              <div v-if="xjbindex==k" class="right_true"><span>✓</span></div>
            </div>
            <a @click="topagebank" class="addbank_">
              <div style="margin-top:4px;transform: rotate(45deg);margin-right:6px"><span
                  style="font-size:18px;color:#bdc0cb;" class="iconfont">&#xe6ab;</span></div>
              <span class="add_">添加银行</span>
            </a>
          </div>
          
          <div style="margin-top:24px;" class="ti_">扣款周期</div>
                <div class="first_timer">
                    <div class="input_timers">
                      <div class="left_timers">
                           <el-select @change="recycleChange"  v-model="recycle" class="item-style" placeholder="选择" clearable>
                            <el-option v-for="item in recycleOptions" :key="item.value" :label="item.label" :value="item.value" />
                        </el-select>
                      </div>
                      <div class="rights_timer">
                         <el-select @change="recycleAChange"  v-model="recycleA" class="item-style" :disabled="isDisabled" placeholder="请选择">
                            <el-option v-for="item in recycleOptionsA" :key="item.value" :label="item.label" :value="item.value" />
                        </el-select>
                      </div>
                    </div>
                </div>
                <!-- <div style="display:flex;align-items:center;margin-top:10px;" class="files_">
                    <input  v-model="checkbooltrue" style="width:10px;height:10px;margin-top: 3px;margin-right:10px;" type="checkbox">
                    <div class="files_btns">
                        当日发起首次定投
                    </div>
                </div> -->
          <div style="margin-top:24px;" class="ti_">定投周期</div>
          <div class="sethurl">
            <div class="left_times_">
              <el-date-picker disabled v-model="protocolFixDay" class="boxs_" type="date" placeholder="定投起始日" value-format="yyyy-MM-dd">
              </el-date-picker>
              <span class="iconfont font_S icon-home-naxt"></span>
            </div>
            <div class="right_times_">
              <el-date-picker v-model="endDate" class="boxs_" type="date" placeholder="定投结束日" value-format="yyyy-MM-dd">
              </el-date-picker>
              <span class="iconfont font_S icon-home-naxt"></span>
            </div>
          </div>

        </div>
        <div class="input_share">
          <div  class="ti_">定投金额</div>
          <div class="input_box">
            <div class="input_">
              <input style="width:90%;height:100%;" @blur="blurs($event)" @keydown="clerInput" v-model="numprice"
                class="input_s" type="number" :placeholder="'最低起投金额'+funddata.startInvestAmt+'元'"
                :min="funddata.startInvestAmt" 
              >
            </div>
            <span class="fen_s">&emsp;元</span>
            <a @click="showrule" class="rules_btn">交易规则</a>
          </div>
          <div v-if="showti" class="red_ti">
            <!-- {{titxt}} -->
          </div>
          <div class="rule_txt">
            <!-- <div class="rule_ti"></div> -->
            <!-- 预计赎回费{{numprice*(matchObj.discountRate/100)||0.00}}元， -->
            <div class="rule_">交易须知:&emsp;购买费率 <span :class="matchObj.discountRate?'throughs':''"
                >{{rate||0.00}}</span>，<span v-if="matchObj.discountRate">折后{{discountRate||0.00}},</span>预估购买费用：{{costPrice||'--'}}，购买确认日：T&emsp;+&emsp;
                {{matchObj.buyConfirmDate||''}}</div>
          </div>
          <div class="rule_file">
            <div class="file_ti">
              <span style="margin-right:6px;color:#CE9B63" class="iconfont">&#xe670;</span>
              <div class="file_txt_ti">为了您的资金安全，交易遵守 <a class="file_btn">同卡进出</a> </div>
            </div>
            <div class="files_">
              <input v-model="checkbool" style="width:10px;height:10px;margin-top: 3px;" type="checkbox">
              <div class="files_btns">
                阅读并同意
                <a @click="outline&&outline.length>0?onoutline():treatyerr()" class="tobtn_">《产品概要》、</a>
                <a @click="contract&&contract.length>0?oncontract():treatyerr()" class="tobtn_">《基金合同》、</a>
                <a  @click="instructions&&instructions.length>0?oninstructions():treatyerr()" class="tobtn_">《招募说明书》、</a>
                <a  @click="obj.protUrl?toPdf(obj.protUrl):tofundnotice(obj.id)" v-for="(obj,j) in propagetwo" :key="j" class="tobtn_">{{obj.protNameDesc}}、</a>
                <a  @click="obj.protUrl?toPdf(obj.protUrl):tofundnotice(obj.id)" v-for="(obj,j) in propage" :key="j" class="tobtn_">{{obj.protNameDesc}}、</a>
                <a  @click="obj.protUrl?toPdf(obj.protUrl):tofundnotice(obj.id)" v-for="(obj,j) in annAndProtlist" :key="j" class="tobtn_">{{'《'+obj.infotitle+'》'||obj.protNameDesc}}、</a>
                等内容
              </div>
            </div>
            <div class="foot_btns">
              <div class="left_num">
                <div class="num_ti">待支付</div>
                <div class="nums_">{{Number(numprice)||0}}<span class="fen_"></span>
                </div>
              </div>
              <a @click="topeges()" class="right_btn">下一步</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="fund-rule">
      <fund-detail-rule ref="fundRule" :fundCode="fundCodes"/>
    </div>
  </section>
</template>
<script>
// import NavRightSide from "@/components/NavRightSide";
import ProgressBar from "@/components/ProgressBar";
import FundDetailRule from "@/views/fund-market/components/FundDetailRule.vue";
import { baseInfo } from "@/api/mysetcast";
import { getDicts } from "@/api/dict.js";
import { protPage } from "@/api/other/index.js";
import { annAndProt } from "@/api/commom.js";
import SiteNav from "@/views/fund-market/components/SiteNav.vue";
import { findXjbAccoList } from "@/api/getMoney.js";

import { findTradeBankAcct, rateMagMatch, getOldBankList,superAcct,queryUserIncomeDetail } from "@/api/addfund";

export default {
  name: "AddProcess",
  components: {
    ProgressBar,
    FundDetailRule,
    SiteNav
  },
  beforeCreate() {
    let token = this.$store.getters.token;
    let _userInfo = localStorage.getItem("customerInfo");
    if (!token || !_userInfo) {
      this.$message.warning("请登录!");
      this.$router.push({ name: "Login" });
      return;
    }
    let userInfo = JSON.parse(_userInfo);
    if (!userInfo.acctFlag || userInfo.acctFlag == "0") {
      this.$message.warning("请先开户!");
      this.$router.push({ name: "OpenAccount" });
      return;
    }
    if (!userInfo.addDataFlag || userInfo.addDataFlag == "0") {
      this.$message.warning("请补充资料!");
      this.$router.push({ name: "PersonalDataSupply" });
      return;
    }
    if (!userInfo.tradePwdFlag || userInfo.tradePwdFlag == "0") {
      this.$message.warning("请设置交易密码!");
      this.$router.push({ name: "OpenAccount" });
      return;
    }
    if (!userInfo.riskFlag || userInfo.riskFlag == "0") {
      this.$message.warning("请先完成风险测评!");
      this.$router.push({ name: "RiskAssessment" });
      return;
    }
  },
  data() {
    return {
      fundCodes: "",
      side: [
        {
          title: "填写定投信息",
          type: 2
        },
        {
          title: "输入交易密码",
          type: 0
        },
        {
          title: "交易结果",
          type: 0
        }
      ],
      xjbList:[],
      xjbindex:0,
      play: [],
      palybank: [], //未隐藏银行卡
      userinfos: {}, //当前用户信息
      bankindex: 2, //默认是索引第一个
      checkbool: false,
      numprice: null,
      banklist: {
        singelLimit: 0,
        singeldayLimit: 0
      }, //默认银行卡数据为第一个
      showti: false,
      cardindex: 0,
      superAccts: {}, //惠金宝账目
      xjbye: "0.00", //现金宝可用余额
      matchObj: {}, //每日
      endDate: null,
      sys_fund_riskleveldata: {}, //产品风险等级
      sys_fund_typedata: {}, //基金类型
      sys_fund_share_typedata: {}, //产品份额
      /** 基金协议 */
      propage: [], //平台协议
      propagetwo: [],
      annAndProtlist: [], //基金协议及公告
      contract: [], //产品合同
      outline: [], //产品概要
      instructions: [], //说明书
      checkbooltrue: false, //是否默认首投日
      recycleOptions: [
        {
          label: "每周",
          value: "1"
        },
        {
          label: "每双周",
          value: "2"
        },
        {
          label: "每月",
          value: "3"
        },
        {
          label: "每日",
          value: "4"
        }
      ],
      recycle: "",
      recycleA: "",
      oldBanklist: [], //旧用户银行卡
      hasOldBank: false, //是否有未绑定的旧卡
      funddata: {}, //当前页面数据
    };
  },
  computed: {
    recycleOptionsA() {
      let ret = [
        {
          label: "星期一",
          value: "1"
        },
        {
          label: "星期二",
          value: "2"
        },
        {
          label: "星期三",
          value: "3"
        },
        {
          label: "星期四",
          value: "4"
        },
        {
          label: "星期五",
          value: "5"
        }
      ];
      const status = this.recycle;
      if (status == 1) {
        // 每周
      } else if (status == 2) {
        // 每双周
      } else if (status == 3) {
        // 每月
        ret = [
          {
            label: "1日",
            value: 1
          },
          {
            label: "2日",
            value: 2
          },
          {
            label: "3日",
            value: 3
          },
          {
            label: "4日",
            value: 4
          },
          {
            label: "5日",
            value: 5
          },
          {
            label: "6日",
            value: 6
          },
          {
            label: "7日",
            value: 7
          },
          {
            label: "8日",
            value: 8
          },
          {
            label: "9日",
            value: 9
          },
          {
            label: "10日",
            value: 10
          },
          {
            label: "11日",
            value: 11
          },
          {
            label: "12日",
            value: 12
          },
          {
            label: "13日",
            value: 13
          },
          {
            label: "14日",
            value: 14
          },
          {
            label: "15日",
            value: 15
          },
          {
            label: "16日",
            value: 16
          },
          {
            label: "17日",
            value: 17
          },
          {
            label: "18日",
            value: 18
          },
          {
            label: "19日",
            value: 19
          },
          {
            label: "20日",
            value: 20
          },
          {
            label: "21日",
            value: 21
          },
          {
            label: "22日",
            value: 22
          },
          {
            label: "23日",
            value: 23
          },
          {
            label: "24日",
            value: 24
          },
          {
            label: "25日",
            value: 25
          },
          {
            label: "26日",
            value: 26
          },
          {
            label: "27日",
            value: 27
          },
          {
            label: "28日",
            value: 28
          }
        ];
      }else{
        ret=[{
            label: "每个交易日",
            value: 1
          }]
      }
      return ret;
    },
    isDisabled() {
      if (this.recycle) {
        return false;
      }
      return true;
    },
    protocolPeriodUnit() {
      const status = this.recycle;
      let ret = 0;
      if (status == 1) {
        // 每周
        ret = 1;
      } else if (status == 2) {
        // 每双周
        ret = 1;
      } else if (status == 3) {
        ret = 0;
      }else{
        ret = 2;
      }
      return ret;
    },
    tradePeriod() {
      const status = this.recycle;
      let ret = 1;
      if (status == 1) {
        // 每周
        ret = 1;
      } else if (status == 2) {
        // 每双周
        ret = 2;
      } else if (status == 3) {
        // 每月
        ret = 1;
      }else{
        ret = 1;
      }
      return ret;
    },
    protocolFixDay() {
      let ret = "";
      const status = this.recycle;

      if (status == 1 || status == 2) {
        // 每周、双周
        const start = Date.now();
        const step = 24 * 60 * 60 * 1000; // 每天
        let retArr = [];
        for (let i = 0; i < 7; i++) {
          let time = start + i * step;
          let day = new Date(time).getDay();
          let date = new Date(time).getDate();
          let obj = {
            name: day,
            value: date,
            time: time
          };
          retArr.push(obj);
        }
        if (this.recycleA) {
          const _tObj = retArr.filter(v => v.name == this.recycleA)[0];
          ret = new Date(_tObj.time);
        }
      } else if (status == 3) {
        // 每月
        const tTime = new Date().getTime() + 1;

        const y = new Date(tTime).getFullYear();
        const m = new Date(tTime).getMonth() + 1;
        const d = Number(this.recycleA);
        ret = new Date(`${y}-${m}-${d}`);
      }else{
        console.log('--执行')
         const tTime = new Date()

          const y = new Date(tTime).getFullYear();
          const m = new Date(tTime).getMonth() + 1;
          const d = new Date(tTime).getDate()+1;
          ret = new Date(`${y}-${m}-${d}`);
          console.log('--2',tTime,y,m,d)
      }
      return ret;
    },
    rate() {
      if (this.matchObj.rate) {
        if (this.matchObj.rate >= 100) {
          return this.matchObj.rate + "元";
        } else {
          return this.matchObj.rate + "%";
        }
      } else {
        return "--";
      }
    },
    discountRate() {
      if (this.matchObj.discountRate) {
        if (this.matchObj.rate >= 100) {
          return this.matchObj.rate + "元";
        } else {
          return (this.matchObj.discountRate * this.matchObj.rate / 100).toFixed(2) + "%";
        }
      } else {
        return "";
      }
    },
    costPrice() {
      if (this.matchObj.rate) {
        if (this.matchObj.discountRate) {
          if (this.matchObj.rate >= 100) {
            return (
              (this.matchObj.discountRate / 100 * this.matchObj.rate).toFixed(
                2
              ) + "元"
            );
          } else {
            return (
              (
                this.matchObj.discountRate /
                100 *
                this.matchObj.rate *
                this.numprice /
                100
              ).toFixed(2) + "元"
            );
          }
        } else {
          if (this.matchObj.rate >= 100) {
            return this.matchObj.rate + "元";
          } else {
            return (
              (this.matchObj.rate * this.numprice / 100).toFixed(2) + "元"
            );
          }
        }
      } else {
        return "--";
      }
    }
  },
  watch: {
    protocolFixDay(val) {
      if (this.protocolFixDay) {
        const _d = new Date(this.protocolFixDay);
        const year = _d.getFullYear();
        let month = _d.getMonth() + 1;
        let day = _d.getDate();
        month = month > 9 ? month : "0" + month;
        day = day > 9 ? day : "0" + day;
        this.endDate= `${year+10}-${month}-${day}`;
      } else {
        this.endDate = null;
      }
    }
  },
  methods: {
    getsuperAcct() {
      // superAcct().then(res => {
      //   if (res.code != 200) {
      //     this.$message.error(res.msg);
      //   } else {
      //     this.superAccts = res.data || {};
      //   }
      // });
      // queryUserIncomeDetail({
      //   userId: this.userinfos.userId,
      //   xjbFlag: "1"
      // }).then(r => {
      //   this.xjbye = r.data ? r.data.totalMoney : "0.00";
      //   // console.log("r", r);
      // });
      this.getxjbList()
    },
    onoutline() {
      if (this.outline && this.outline[0] && this.outline[0].protUrl) {
        this.toPdf(this.outline[0].protUrl);
      } else {
        this.tofundnotice(this.outline[0].id);
      }
    },
    oncontract() {
      if (this.contract && this.contract[0] && this.contract[0].protUrl) {
        this.toPdf(this.contract[0].protUrl);
      } else {
        this.tofundnotice(this.contract[0].id);
      }
    },
    oninstructions() {
      if (
        this.instructions &&
        this.instructions[0] &&
        this.instructions[0].protUrl
      ) {
        this.toPdf(this.instructions[0].protUrl);
      } else {
        this.tofundnotice(this.instructions[0].id);
      }
    },
    recycleChange(val) {
      if (val) {
        this.recycleA = "";
      }
    },
    getDictionaries(val, option) {
      this.getDicts(val).then(res => {
        if (res.code === 200) {
          res.data.forEach(item => {
            // this[option][item.dictValue] = item.dictLabel
            this.$set(this[option], item.dictValue, item.dictLabel);
          });
        }
      });
    },
    annAndProtdata() {
      annAndProt({
        fundCode: this.funddata.fundCode,
        protLik: "5",
        protType: "2",
        status: "1"
      }).then(res => {
        let annList = res.data.annList;
        let protList = res.data.protList;
        const len = annList.length;
        const len2 = protList.length;
        let arr1 = [];
        let arr2 = [];
        for (let i = 0; i < len; i++) {
          if (annList[i].infotitle.includes("合同")) {
            this.contract.push(annList[i]);
          } else if (annList[i].infotitle.includes("说明书")) {
            this.instructions.push(annList[i]);
          } else if (annList[i].infotitle.includes("概要")) {
            this.outline.push(annList[i]);
          } else {
            arr1.push(annList[i]);
          }
        }
        for (let i = 0; i < len2; i++) {
          if (protList[i].protNameDesc.includes("合同")) {
            this.contract.push(protList[i]);
          } else if (protList[i].protNameDesc.includes("说明书")) {
            this.instructions.push(protList[i]);
          } else if (protList[i].protNameDesc.includes("概要")) {
            this.outline.push(protList[i]);
          } else {
            arr2.push(protList[i]);
          }
        }
        this.annAndProtlist = [...arr1, ...arr2];
      });
    },
    /** 获取产品协议 */
    getProtocol() {
      protPage({
        protType: 1,
        status: 1,
        protLink: 5,
        pageNum: 1,
        pageSize: 10
      }).then(res => {
        this.propage = res.data.data;
      });
    },
    getProtocoltwo() {
      protPage({
        protType: 1,
        status: 1,
        protLink: 4,
        pageNum: 1,
        pageSize: 10
      }).then(res => {
        this.propagetwo = res.data.data;
      });
    },
    recycleAChange(val) {
      // if (val) {
      //   // this.recycleA = "";
      // }
      console.log(val);
    },
    bankswith(o) {
      this.bankindex = o;
      if(o==1){
        this.getxjbList()
      }else{
          this.getlits();
      }
    },
    getcardxjb(obj, i) {
      this.xjbindex = i;
      console.log('--',this.xjbindex)
      this.superAccts = this.xjbList[i]; //切换银行卡
    },
    getxjbList(){
      findXjbAccoList({}).then(res => {
        this.superAccts=res.data&&res.data[0]
        this.xjbList = res.data;
        this.xjbindex=0;
      });
    },
    blurs() {
      let teg = /^[0-9]+.?[0-9]*$/;
      if (!this.numprice && !teg.test(this.numprice)) {
        this.showti = true;
        return;
      } else {
        this.showti = false;
        rateMagMatch({
          fundCode: this.funddata.fundCode,
          rateType: "1",
          amount: Number(this.numprice)
        }).then(r => {
          this.matchObj = r.data && r.data.length ? r.data[0] : {};
        });
      }
    },
    clerInput(e) {
      let key = e.key;
      if (
        key === "e" ||
        key === "E" ||
        key === "-" ||
        key === "+" ||
        key === "."
      ) {
        e.returnValue = false;
        return false;
      }
      return true;
    },
    getcard(obj, i) {
      this.cardindex = i;
      this.banklist = this.palybank[i]; //切换银行卡
    },
    getfund(a) {
      baseInfo({
        fundCode: a
      }).then(res => {
        if (res.code != 200) {
          this.$message.error(res.msg);
        } else {
          this.funddata = res.data;
          if (this.funddata.buyStatus === "0") {
            this.$message.warning("当前基金暂停购买!");
            this.$router.push({ name: "FundDetail", params: { fundCode: a } });
          }
          this.getlits();
          this.getDictionaries("sys_fund_risklevel", "sys_fund_riskleveldata");
          this.getDictionaries(
            "sys_fund_share_type",
            "sys_fund_share_typedata"
          );
          this.getDictionaries("sys_fund_type", "sys_fund_typedata");
          this.getProtocol();
          this.getProtocoltwo();
          this.annAndProtdata();
        }
      });
    },
    getlits() {
      findTradeBankAcct().then(res => {
        if (res.code != 200) {
          this.$message.error(res.msg);
        } else {
          // 未绑卡时判断是否有旧卡
          let datas = res.data;
          if (this.oldBanklist.length > 0 && datas.length === 0) {
            this.hasOldBank = true;
          }

          this.palybank = res.data;
          this.banklist = (res.data && res.data[0]) || {};
          let a = JSON.stringify(datas);
          let b = JSON.parse(a);
          this.play = b.map(item => {
            let str = item.bankAccount.slice(0, 4);
            let str2 = item.bankAccount.slice(-4);
            item.bankAccount = str + " **** **** " + str2;
            return item;
          });
        }
      });
    },
    topage() {
      this.$router.push({ name: "FundMarket" });
    },
    showrule() {
      this.$refs.fundRule.show = true;
    },
    topagebank() {
      let route = this.hasOldBank ? "OldBankCard" : "BankCardAdd";
      this.$router.push({ name: route });
    },
    togos() {
      this.$router.go(-1);
    },
    topeges() {

        let teg = /^[0-9]+.?[0-9]*$/;
        if (!this.numprice && !teg.test(this.numprice)) {
          this.$message.error("请正确输入定投金额！");
          return;
        }
        if(Number(this.numprice) < Number(this.funddata.startInvestAmt)){
          this.$message.error('最低起投'+this.funddata.startInvestAmt+'元')
          return
        }
        if (!this.checkbool) {
          this.$message.error("请阅读并同意基金定投相关协议！");
        } else if (JSON.stringify(this.banklist) == "{}") {
          this.$message.error("请正确添加银行卡");
        } else if (!this.protocolFixDay || !this.endDate) {
          this.$message.error("请正确选择定投周期！");
        } else {
          let dates = new Date(this.protocolFixDay);
          let a = dates.getFullYear();
          let b =
            dates.getMonth() + 1 < 10
              ? "0" + (dates.getMonth() + 1)
              : dates.getMonth() + 1;
          let c = dates.getDate();
          let yymms = String(a) + b;
          //
          let dates1 = new Date(this.endDate);
          let a1 = dates1.getFullYear();
          let b1 =
            dates1.getMonth() + 1 < 10
              ? "0" + (dates1.getMonth() + 1)
              : dates1.getMonth() + 1;
          let c1 =
            dates1.getDate() < 10 ? "0" + dates1.getDate() : dates1.getDate();
          let yymmdd = String(a1) + b1 + c1;
          let params = {
            protocolFixDay: c,
            firstExchdate: yymms,
            endDate: yymmdd,
            protocolPeriodUnit: this.recycle == 1 || this.recycle == 2 ? "1" :this.recycle=='4'?'2': "0",
            tradePeriod: this.recycle == 1 || this.recycle == 3||this.recycle == 4 ? "1" : "2",
            numprice: Number(this.numprice),
            funddata: this.funddata,
            channelSource: 1,
            tradeAcco: this.banklist.tradeAcco,
            banklist: this.banklist,
            newtimes: dates,
            payType: this.bankindex
          };
          this.$router.push({ name: "AddProPassword", params: { params } });
        }
      
    }
  },
  created() {
    getOldBankList().then(result => {
      this.oldBanklist = result.data || [];
    });

    this.userinfos = JSON.parse(window.localStorage.getItem("customerInfo")) || {};
    this.getsuperAcct()
    
    let a = sessionStorage.getItem("DtaddfundCode");
    if (!this.$route.params.fundCode && a != "undefined") {
      this.getfund(a);
      this.fundCodes = a;
    } else {
      window.sessionStorage.setItem(
        "DtaddfundCode",
        this.$route.params.fundCode
      );
      this.fundCodes = this.$route.params.fundCode;
      this.getfund(this.$route.params.fundCode);
    }
  }
};
</script>
<style lang="less" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

::-webkit-input-placeholder {
  color: #bdc0cb;
}

::-moz-placeholder {
  color: #bdc0cb;
}

input[type="checkbox"]:checked {
  background-color: #ce9b63 !important;
  // background: #ce9b63!important;
}

/* firefox 19+ */
:-ms-input-placeholder {
  color: #bdc0cb;
}

/* ie */
input:-moz-placeholder {
  color: #bdc0cb !important;
}
.fund-rule {
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 3;
}
.el-input__inner {
  border: none !important;
}

.first_timer {
  width: 520px;

  .input_timers {
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 40px;
    line-height: 38px;
    display: flex;
    justify-content: space-between;
    .left_timers {
      width: 246px;
      height: 40px;
      border: 1px solid #e0e2e8;
      border-radius: 2px;
    }
    .rights_timer {
      width: 246px;
      height: 40px;
      border: 1px solid #e0e2e8;
      border-radius: 2px;
    }
    /deep/.el-input__inner {
      border: none;
    }
  }
}
.sethurl {
  display: flex;
  align-items: center;

  div {
    width: 250px;
    position: relative;

    .font_S {
      font-size: 12px;
      color: #bdc0cb;
      position: absolute;
      right: 14px;
      top: 14px;
      transform: rotate(90deg);
    }
  }

  .left_times_ {
    margin-right: 22px;
  }
}

.PublicFundProcess {
  // padding: 0px 0px 50px 0px;
  min-height: 1200px;
  // display: flex;
  background: #f3f4f6;

  .publicfundprocess_cont {
    background: #fff;
    min-height: 1290px;
    width: 1200px;

    .title_ {
      display: inline-block;
      padding: 40px 40px 0px 40px;
      height: 60px;
      font-size: 20px;
      color: #1f1f1f;
    }

    .side_cont {
      height: 146px;
      padding: 0px 0px 0px 80px;
      display: flex;
      align-items: center;
    }

    .solids_ {
      height: 2px;
      background: linear-gradient(270deg, #eabf96 0%, #f7d9b7);
      box-shadow: 0px 4px 12px 0px #edf1f7;
    }

    .card_ {
      padding: 0px 40px;
      margin-top: 30px;

      .titles_ {
        font-size: 18px;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
        font-weight: 700;
        text-align: LEFT;
        color: #1f1f1f;
      }

      .card_new {
        border-radius: 12px;
        box-shadow: 0px 5px 10px 0px #edf1f7;
        margin-top: 24px;
        height: 80px;
        padding: 24px 24px 0 24px;

        // width: 802px;
        .card_l {
          display: flex;
          // align-items: center;
          justify-content: space-between;

          .right_ti {
            .nema_ti {
              .num {
                color: #bdc0cb;
                font-size: 18px;
                font-weight: bold;
                margin-right: 10px;
              }

              .name_ {
                color: #1f1f1f;
                font-size: 18px;
              }
            }

            .tags {
              margin-top: 16px;
              display: flex;
              align-items: center;

              .tag_ {
                padding: 6px 20px;
                border-radius: 14px;
                font-size: 12px;
                color: #8691a8;
                font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
                font-weight: 400;
                border: 1px solid #e0e2e8;
                margin-right: 14px;

                &:first-child {
                  border: none;
                  background: #fff5ea;
                  color: #ce9b63;
                  padding: 7px 20px;
                }
              }
            }
          }

          .right_btn {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            height: 30px;

            span {
              font-size: 12px;
              font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
              font-weight: 400;
              text-align: LEFT;
              color: #000000;
            }

            img {
              width: 12px;
              height: 12px;
              margin-right: 12px;
            }
          }
        }
      }
    }

    .play_type {
      padding: 0px 40px;
      margin-top: 40px;

      .ti_ {
        font-size: 16px;
        font-family: ABeeZee, ABeeZee-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #1f1f1f;
        margin-bottom: 14px;
      }

      .bank_card {
        .bank_list {
          width: 468px;
          height: 46px;
          display: flex;
          align-items: center;
          padding: 0px 25px;
          position: relative;
          margin-bottom: 24px;
          border: 1px solid #e0e2e8;
          border-radius: 2px;

          .l_imgs {
            width: 26px;
            height: 26px;
            margin-right: 19px;
          }

          .c_name {
            font-size: 16px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Light;
            font-weight: 300;
            text-align: LEFT;
            color: #25293d;
            width: 127px;
          }

          .c_nameac {
            font-size: 16px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: #25293d;
          }

          .r_share {
            font-size: 12px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #25293d;

            .color_ {
              color: #ce9b63;
            }
          }

          .right_true {
            position: absolute;
            right: -16.5px;
            top: -16.5px;
            width: 0px;
            height: 0px;
            border: 16px solid #f7d9b7;
            border-color: transparent transparent #eabf96 transparent;
            transform: rotate(45deg);

            // background:linear-gradient(116deg, #fff 0%, #fff 49.9%, #f7d9b7 0%, #eabf96 100%);
            span {
              color: #fff;
              position: absolute;
              transform: rotate(-35deg);
              top: -1px;
              right: -5px;
            }
          }
        }

        .bank_listac {
          border: 1px solid;
          border-image: linear-gradient(95deg, #f7d9b7 0%, #eabf96 100%) 1 1;
        }

        .bankcard_ {
          margin-bottom: 30px;
          padding: 30px 24px 6px 24px;
          background: #f6f5f3;
          display: flex;
          border-radius: 2px;
          // align-items: center;
          // justify-content: space-between;
          flex-wrap: wrap;
          flex-direction: row;

          .backs_ac {
            width: 208px !important;
            border: 1px solid #ce9b63;
            height: 54px !important;
            border-radius: 4px;
          }

          .banks_ {
            padding: 22px 20px;
            width: 210px;
            height: 56px;
            background: #fff;
            margin-right: 24px;
            margin-bottom: 24px;
            position: relative;
            border-radius: 4px;

            &:nth-child(4n + 4) {
              margin-right: 0px;
            }

            .right_true {
              position: absolute;
              right: -16.5px;
              top: -16.5px;
              width: 0px;
              height: 0px;
              border: 16px solid #f7d9b7;
              border-color: transparent transparent #eabf96 transparent;
              transform: rotate(45deg);

              // background:linear-gradient(116deg, #fff 0%, #fff 49.9%, #f7d9b7 0%, #eabf96 100%);
              span {
                color: #fff;
                position: absolute;
                transform: rotate(-35deg);
                top: -1px;
                right: -5px;
              }
            }

            .top_ban {
              display: flex;
              align-items: center;

              img {
                width: 22px;
                height: 22px;
                margin-right: 7px;
              }

              .bank_name {
                font-size: 18px;
                font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
                font-weight: 400;
                color: #1f1f1f;
              }
            }

            .cardnum_ {
              margin-top: 20px;
              font-size: 18px;
              font-family: Bahnschrift, Bahnschrift-Light;
              font-weight: 300;
              color: #1f1f1f;
            }
          }

          .addbank_ {
            width: 248px;
            height: 98px;
            border: 1px dashed #bdc0cb;
            border-radius: 4px;
            margin-bottom: 24px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              width: 20px;
              height: 20px;
              margin-right: 8px;
            }

            .add_ {
              font-size: 18px;
              font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
              font-weight: 400;
              color: #bdc0cb;
            }
          }
        }
      }

      .input_share {
        margin-top: 30px;
        .ti_ {
          font-size: 16px;
          font-family: ABeeZee, ABeeZee-Regular;
          font-weight: 400;
          text-align: LEFT;
          color: #1f1f1f;
          margin-bottom: 14px;
        }

        .input_box {
          display: flex;
          align-items: center;

          .input_ {
            display: flex;
            align-items: center;
            padding-left: 24px;
            justify-content: space-between;
            border: 1px solid #e0e2e8;
            border-radius: 2px;
            width: 494px;
            height: 46px;

            .long {
              font-size: 14px;
              font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
              font-weight: 400;
              color: #ce9b63;
            }
          }

          .fen_s {
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            text-align: LEFT;
            color: #1f1f1f;
            margin-right: 14px;
          }

          .rules_btn {
            font-size: 12px;
            font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
            font-weight: 400;
            color: #ce9b63;
          }
        }

        .red_ti {
          margin-top: 19px;
          color: red;
          font-size: 12px;
        }

        .rule_txt {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 850px;
          line-height: 16px;
          // height: 64px;
          padding: 14px 20px;
          background: rgba(255, 245, 234, 0.5);
          border-radius: 2px;
          margin-top: 30px;
          font-size: 12px;
          font-family: Microsoft YaHei UI, Microsoft YaHei UI-Light;
          font-weight: 300;
          color: #ce9b63;

          .rule_ {
            // margin-top: 4px;
          }
          .throughs{
            text-decoration:line-through;
          }
        }

        .rule_file {
          margin-top: 30px;

          .file_ti {
            display: flex;
            align-items: center;

            .file_img_ti {
              width: 22px;
              height: 22px;
              margin-right: 3px;
            }

            .file_txt_ti {
              font-size: 14px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              color: #1f1f1f;

              .file_btn {
                color: #ce9b63;
              }
            }
          }

          .files_ {
            margin-top: 17px;
            display: flex;

            .files_btns {
              width: 500px;
              margin-left: 10px;
              font-size: 12px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              color: #1f1f1f;

              .tobtn_ {
                color: #ce9b63;
              }
            }
          }

          .foot_btns {
            display: flex;
            margin-top: 56px;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 70px;

            .left_num {
              .num_ti {
                font-size: 12px;
                font-family: Poppins, Poppins-Regular;
                font-weight: 400;
                color: #1f1f1f;
              }

              .nums_ {
                font-size: 26px;
                font-family: Microsoft YaHei UI, Microsoft YaHei UI-Bold;
                font-weight: 700;
                color: #e45247;

                .fen_ {
                  font-size: 12px;
                  font-family: Poppins, Poppins-Regular;
                  font-weight: 400;
                  color: #1f1f1f;
                  margin-left: 10px;
                }
              }
            }

            .right_btn {
              background: linear-gradient(103deg, #f7d9b7 0%, #eabf96 100%);
              border-radius: 2px;
              height: 48px;
              line-height: 48px;
              text-align: center;
              width: 200px;
              color: #b88141;
            }
          }
        }
      }
    }
  }
}
</style>